// Add click event listener to all elements with the 'card-buttons' class
document.addEventListener('DOMContentLoaded', () => {


  const fullscreenButton = document.querySelector('.openvideofullscreen');
  const videoElement = document.querySelector('.advertisement-video');

  if (fullscreenButton && videoElement) {
      fullscreenButton.addEventListener('click', () => {
          if (videoElement.requestFullscreen) {
              videoElement.requestFullscreen();
          } else if (videoElement.webkitRequestFullscreen) { // For Safari
              videoElement.webkitRequestFullscreen();
          } else if (videoElement.msRequestFullscreen) { // For IE/Edge
              videoElement.msRequestFullscreen();
          }
      });
  }


  document.querySelectorAll('.card-buttons').forEach((button) => {
    button.addEventListener('click', (event) => {
      event.stopPropagation(); // Prevent the click event from bubbling up
      event.preventDefault(); // Prevent any default behavior (e.g., href navigation)
      // Find the parent .card element
      const parentCard = event.currentTarget.closest('.card');

      // Find the popupToolbar within the parent .card
      const popup = parentCard.querySelector('.popup-toolbar');

      // Toggle the 'active' class to show/hide the popup
      if (popup) {
        popup.classList.toggle('active');
      }

      // Close other popups
      document.querySelectorAll('.popupToolbar.active').forEach((openPopup) => {
        if (openPopup !== popup) {
          openPopup.classList.remove('active');
        }
      });
    });
  });


  function cardfunctionsClicks() {

    document.querySelectorAll('.card-buttons').forEach((button) => {
      button.addEventListener('click', (event) => {
        event.stopPropagation(); // Prevent the click event from bubbling up
        event.preventDefault(); // Prevent any default behavior (e.g., href navigation)
        // Find the parent .card element
        const parentCard = event.currentTarget.closest('.card');

        // Find the popupToolbar within the parent .card
        const popup = parentCard.querySelector('.popup-toolbar');

        // Toggle the 'active' class to show/hide the popup
        if (popup) {
          popup.classList.toggle('active');
        }

        // Close other popups
        document.querySelectorAll('.popupToolbar.active').forEach((openPopup) => {
          if (openPopup !== popup) {
            openPopup.classList.remove('active');
          }
        });
      });
    });

  }





// Close popups when clicking outside
document.addEventListener('click', (event) => {
  const openPopups = document.querySelectorAll('.popup-toolbar.active');

  openPopups.forEach((popup) => {
    if (!popup.contains(event.target)) {
      popup.classList.remove('active');
    }
  });
});


// Add click event listener to all elements with the 'card-buttons' class
document.querySelectorAll('.opensharetoolbar').forEach((button) => {
  button.addEventListener('click', (event) => {
      event.stopPropagation(); // Prevent the click event from bubbling up

      // Check if the click was on an <a> inside the popup
      const link = event.target.closest('.popup-toolbar a');
      if (link) {
          event.preventDefault(); // Prevent default navigation
          window.open(link.href, '_blank'); // Open in a new tab
          return; // Stop further execution
      }

      event.preventDefault(); // Prevent any default behavior (e.g., href navigation)

      // Find the parent .btn element
      const parentCard = event.currentTarget.closest('.btn');
      console.log(parentCard);

      // Find the popupToolbar within the parent .btn
      const popup = parentCard.querySelector('.popup-toolbar');

      // Toggle the 'active' class to show/hide the popup
      if (popup) {
          popup.classList.toggle('active');
      }

      // Close other popups
      document.querySelectorAll('.popup-toolbar.active').forEach((openPopup) => {
          if (openPopup !== popup) {
              openPopup.classList.remove('active');
          }
      });
  });
});

// Close popups when clicking outside
document.addEventListener('click', (event) => {
  const openPopups = document.querySelectorAll('.popup-toolbar.active');

  openPopups.forEach((popup) => {
    if (!popup.contains(event.target)) {
      popup.classList.remove('active');
    }
  });
});


// Add an event listener for scroll
window.addEventListener('scroll', () => {
  const header = document.querySelector('.header'); // Select the header element
  const scrollPosition = window.scrollY; // Get the current scroll position

  if (scrollPosition > 50) { // Add the 'fixed' class after scrolling 50px
    header.classList.add('fixed');
  } else { // Remove the 'fixed' class if scrolled back to the top
    header.classList.remove('fixed');
  }
});


// Wait for the DOM to load
  // Select all elements with the class 'legalflixsearch'
  const legalflixItems = document.querySelectorAll('.legalflixsearch');
  const toolbarItems = document.querySelectorAll('.toolbar-item');

  // Add click event listener to each 'legalflixsearch' item
  legalflixItems.forEach((item) => {
    item.addEventListener('click', (event) => {
      // console.log('Item clicked:', event.currentTarget); // Debug: Log the clicked item

      const overlay = document.querySelector('.overlaysearch');
      overlay.classList.toggle('hidden'); // Toggles the 'hidden' class

      // If the clicked item already has 'active', remove it and stop further actions
      if (event.currentTarget.classList.contains('active')) {
        event.currentTarget.classList.remove('active');
        return; // Stop further actions
      }

      // Remove 'active' class from all 'legalflixsearch' items
      legalflixItems.forEach((el) => el.classList.remove('active'));

      // Remove 'active' class from all 'toolbar-item' items
      toolbarItems.forEach((el) => el.classList.remove('active'));

      // Add 'active' class to the clicked item
      event.currentTarget.classList.add('active');

      // console.log('Active class toggled on:', event.currentTarget); // Debug: Log the toggled item
    });
  });

    const secondSearchButton = document.getElementById('secondsearch');
    const legalflixSearch = document.querySelector('.toolbar-item.legalflixsearch');

    if (secondSearchButton && legalflixSearch) {
        console.log('Second search button and legalflix search found!');
        const overlay = document.querySelector('.overlaysearch');

        secondSearchButton.addEventListener('click', () => {
            overlay.classList.toggle('hidden'); // Toggles the 'hidden' class

            // Trigger a click on the legalflixsearch element
            legalflixSearch.click();
        });
    }

    const searchInput = document.getElementById('searchInput');
    const close = document.getElementById('activeclose');
    const searchResults = document.querySelector('.search-results'); // Reference to .search-results div

    let debounceTimeout; // To manage debouncing
    let isFetching = false; // To ensure only one AJAX call at a time

    searchInput.addEventListener('input', () => {
      const query = searchInput.value.trim();

      // Add a class when more than 3 letters are typed
      if (query.length > 3) {
        searchInput.classList.add('active');
        close.classList.add('active');
      } else {
        searchInput.classList.remove('active');
        close.classList.remove('active');
      }

      // Debounce the AJAX call
      clearTimeout(debounceTimeout);
      if (query.length > 3) {
        searchResults.innerHTML = ''; // Clear search results when query is too short

        debounceTimeout = setTimeout(() => {
          if (!isFetching) {
            isFetching = true;
            const searchTitle = document.getElementById('search-titel');
            if (searchTitle) {
              searchTitle.style.display = 'none'; // Hide the element
            }
            // Clear previous search results and show loading text
            searchResults.innerHTML = '<p class="noresults">zoeken....</p>';

            const formData = new FormData();
            formData.append('query', query);

            // Perform the AJAX call
            fetch('/legalflix/search', {
              method: 'POST',
              body: formData, // Send FormData instead of JSON
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text(); // Expecting HTML as response
              })
              .then((html) => {

                // Inject the returned HTML into the .search-results div
                searchResults.innerHTML = html;

                // Additional UI adjustments
                adjustCardWidth();
                cardfunctionsClicks();
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
                searchResults.innerHTML = '<p class="noresults">zoeken....</p>'; // Error message
              })
              .finally(() => {
                isFetching = false; // Reset the fetching state
              });
          }
        }, 300); // Adjust debounce time as needed
      }
    });

  // Clear search and close results on clicking the close button
  close.addEventListener('click', () => {
    searchInput.value = '';
    searchInput.classList.remove('active');
    close.classList.remove('active');
    searchResults.innerHTML = ''; // Clear results
  });


  const activeClose = document.getElementById('activeclose');

  // Clear input and remove active class when close button is clicked
  activeClose.addEventListener('click', () => {
    // Clear the input field
    searchInput.value = '';

    // Remove the 'active' class from the input field and close button
    searchInput.classList.remove('active');
    activeClose.classList.remove('active');
  });

  // Add 'active' class to input field when typing more than 3 characters
  searchInput.addEventListener('input', () => {
    const query = searchInput.value.trim();

    if (query.length > 3) {
      searchInput.classList.add('active');
      activeClose.classList.add('active'); // Activate close button
    } else {
      searchInput.classList.remove('active');
      activeClose.classList.remove('active'); // Deactivate close button
    }
  });

function adjustCardWidth() {
  // Select all .no-slide-outline containers
  const containers = document.querySelectorAll('.no-slide-outline');

  // Loop through each container
  containers.forEach((container) => {

    // console.log(container);


    const cards = container?.querySelectorAll('.card');
    if (!container || cards.length === 0) return;

    // Apply only for desktop screen sizes (1024px or wider)
    if (window.innerWidth < 780) {
      // Reset styles for smaller screens if needed
      cards.forEach((card) => {
        card.style.width = '';
        card.style.marginRight = '';
      });
      return;
    }
    if (window.innerWidth > 2000) {
      var cardsPerRow = 7;
    }
    else if (window.innerWidth > 1800) {
      var cardsPerRow = 6;
    }
    else if (window.innerWidth > 1320) {
      var cardsPerRow = 5;
    }
    else if (window.innerWidth > 1180) {
      var cardsPerRow = 4;
    }
    else if (window.innerWidth > 760) {
      var cardsPerRow = 2;
    }
    else{
      var cardsPerRow = 5;
    }
    // Total cards per row

    // Set the fixed margin-right for all cards except the last in the row
    const cardMarginRight = 32; // Fixed margin in pixels

    // Calculate the available width for cards
    const containerWidth = container.offsetWidth;

    // Calculate the total width of margins in one row
    const totalMarginWidth = cardMarginRight * (cardsPerRow - 1);

    // Calculate the width for each card
    const cardWidth = Math.floor((containerWidth - totalMarginWidth) / cardsPerRow);

    // console.log(cardWidth);

    // Apply calculated width and margin-right to each card
    cards.forEach((card, index) => {
      card.style.width = `${cardWidth}px`;

      // Remove margin-right for the fifth card in each row
      if ((index + 1) % cardsPerRow === 0) {
        card.style.marginRight = '0px';
      } else {
        card.style.marginRight = `${cardMarginRight}px`;
      }
    });
  });
}



  // Adjust card widths on page load
  adjustCardWidth();

  // Adjust card widths on window resize
  window.addEventListener('resize', adjustCardWidth);


// document.addEventListener('DOMContentLoaded', () => {
//   const tabButtons = document.querySelectorAll('.tab-button');
//   const tabPanes = document.querySelectorAll('.tab-pane');

//   tabButtons.forEach((button) => {
//     button.addEventListener('click', () => {
//       // Remove active class from all buttons and panes
//       tabButtons.forEach((btn) => btn.classList.remove('active'));
//       tabPanes.forEach((pane) => pane.classList.remove('active'));
//       // Add active class to the clicked button and corresponding pane
//       button.classList.add('active');
//       const targetPane = document.getElementById(button.dataset.tab);
//       targetPane.classList.add('active');

//       adjustCardWidth();


//     });
//   });
// });


  const tabButtons = document.querySelectorAll('.tab-button');
  const tabPanes = document.querySelectorAll('.tab-pane');

  tabButtons.forEach((button) => {
    button.addEventListener('click', () => {
      // Remove active class from all buttons and panes
      tabButtons.forEach((btn) => btn.classList.remove('active'));
      tabPanes.forEach((pane) => pane.classList.remove('active'));

      // Add active class to the clicked button and corresponding pane
      button.classList.add('active');
      const targetPane = document.getElementById(button.dataset.tab);
      targetPane.classList.add('active');

      adjustCardWidth();


    });
  });


  document.body.addEventListener('click', function (event) {
    // Check if the clicked element has the 'favorite__front-end' class
    if (event.target.closest('.favorite__front-end')) {
      event.stopPropagation();

      // Get the parent element with the 'favorite__front-end' class
      const favoriteElement = event.target.closest('.favorite__front-end');

      // Retrieve the signup and classname values
      const signup = favoriteElement.querySelector('.get-signup')?.value;
      const classname = favoriteElement.querySelector('.get-class')?.value;

      // Prepare FormData
      const formData = new FormData();
      formData.append('Signup', signup);
      formData.append('Classname', classname);

      // Send the POST request using Fetch API
      fetch('dashboard/favorieten/addToFavorites', {
        method: 'POST',
        body: formData // Send the FormData directly
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        // Find the <span> within the clicked element and toggle its text
        const spanElement = favoriteElement.querySelector('span');
        if (spanElement) {
          const favCounter = document.querySelector('.favocount'); // Select the counter element

          if (spanElement.textContent.trim() === 'Toevoegen aan mijn lijst') {
            spanElement.textContent = 'Verwijderen uit mijn lijst';

            // Increment the counter by 1
            if (favCounter) {
              favCounter.textContent = parseInt(favCounter.textContent || 0) + 1;
            }
          } else {
            spanElement.textContent = 'Toevoegen aan mijn lijst';

            // Decrement the counter by 1
            if (favCounter) {
              favCounter.textContent = Math.max(parseInt(favCounter.textContent || 0) - 1, 0);
            }
          }

          // Check if the body has the 'favoritespage' class
          if (document.body.classList.contains('favoritespage')) {
            // If the text is "Toevoegen aan mijn lijst", remove the closest 'card' container
            if (spanElement.textContent.trim() === 'Toevoegen aan mijn lijst') {
              const cardContainer = favoriteElement.closest('.card'); // Find the closest parent with class 'card'
              if (cardContainer) {
                cardContainer.remove(); // Remove the card container from the DOM
                adjustCardWidth();
              }
            }
          }
        }
      })
      .catch(error => {
        console.error('Error adding to favorites:', error);
      });
    }
  });


  const video = document.getElementById('background-video');

  if (video) {
    video.muted = true; // Ensure the video is muted for autoplay

      let loopCount = 0; // Initialize loop counter

      video.play()
          .then(() => {
              // Set the video to start at the specified time
              video.currentTime = 15;

              const stopTime = 75; // Stop video at 75 seconds (60s duration after 15s start)
              const interval = setInterval(() => {
                  if (video.currentTime >= stopTime) {
                      loopCount++; // Increment loop counter
                      if (loopCount < 2) {
                          // Reset and play again for the first loop
                          video.pause();
                          video.currentTime = 15; // Reset video back to 15 seconds
                          video.play();
                      } else {
                          // Stop the video and keep the poster after the second loop
                          video.pause();
                          clearInterval(interval); // Clear the interval to stop checking
                          video.src = ''; // Remove the video source
                          video.load(); // Reset the video element to show the poster
                      }
                  }
              }, 200); // Check every 200ms
          })
          .catch(error => {
              console.error("Autoplay failed or video could not play:", error);
          });
  }


  // const video = document.getElementById('background-video');

  // if (video) {
  //   video.muted = true; // Ensure the video is muted for autoplay

  //   video.play()
  //     .then(() => {
  //       // Set the video to start at 15 seconds
  //       video.currentTime = 15;

  //       const stopTime = 75; // Stop video at 75 seconds (60s duration after 15s start)
  //       const interval = setInterval(() => {
  //         if (video.currentTime >= stopTime) {
  //           video.pause(); // Stop the video
  //           video.currentTime = 15; // Reset video back to 15 seconds
  //           video.play(); // Start the video over
  //         }
  //       }, 200); // Check every 200ms
  //     })
  //     .catch(error => {
  //       console.error("Autoplay failed or video could not play:", error);
  //     });
  // }

  // Function to check if an element exists and add clipboard functionality
  function addClipboardFunctionality() {
    const elementId = "copyLink";
    const element = document.getElementById(elementId);

    if (element) {
        // Element exists, add event listener
        element.addEventListener("click", function (e) {
            e.preventDefault(); // Prevent default link behavior

            // Get the current URL
            const currentURL = window.location.href;

            // Copy to clipboard
            navigator.clipboard.writeText(currentURL).then(function () {
                alert("Link copied to clipboard: " + currentURL);
            }).catch(function (err) {
                console.error("Failed to copy: ", err);
            });
        });

        // console.log(`Clipboard functionality added to element with ID "${elementId}".`);
    } else {
        // console.log(`Element with ID "${elementId}" does not exist.`);
    }
  }

  // Call the function
  addClipboardFunctionality();


    const container = document.getElementById("slider-container");
    const spinner = document.getElementById("loading-spinner");

    // Activate only if the lazyload-blocks class is present
    if (!document.querySelector('.lazyload-blocks')) {
        // console.log("No lazyload-blocks class found. Infinite scroll disabled.");
        return;
    }

    let offset = 20; // Start offset (adjust based on initial load)
    let loading = false; // Prevent multiple requests
    let allContentLoaded = false; // Flag to stop requests when all content is loaded
    const limit = 20; // Number of items per load

    const loadMoreContent = () => {
        if (loading || allContentLoaded) return;
        loading = true;
        spinner.style.display = "block";

        // Send AJAX request
        fetch(`${window.location.href}?offset=${offset}`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then((response) => response.text())
            .then((html) => {
                if (!html.trim()) {
                    // If no content is returned, stop further requests
                    allContentLoaded = true;
                    spinner.style.display = "none";
                    return;
                }

                const tempDiv = document.createElement("div");
                tempDiv.innerHTML = html;
                const newContent = tempDiv.children;

                // Append with a smooth fade-in effect
                [...newContent].forEach((item) => {
                    item.style.opacity = 0;
                    container.appendChild(item);
                    setTimeout(() => {
                        item.style.transition = "opacity 0.5s";
                        item.style.opacity = 1;
                    }, 50);
                });

                offset += limit;
                loading = false;
                spinner.style.display = "none";

                adjustCardWidth()

                cardfunctionsClicks();


            })
            .catch((error) => {
                console.error("Error loading more content:", error);
                loading = false;
                spinner.style.display = "none";
            });
    };

    // Add scroll event listener
    window.addEventListener("scroll", () => {
        if (allContentLoaded) return;

        const scrollPosition = window.innerHeight + window.scrollY;
        const threshold = document.body.offsetHeight - 200; // Trigger 200px before the bottom

        if (scrollPosition >= threshold) {
            loadMoreContent();
        }
    });

  });

  document.addEventListener("DOMContentLoaded", () => {
    const sliders = document.querySelectorAll(".live-slider");

    sliders.forEach(slider => {
        // Create a MutationObserver to watch for DOM changes
        const observer = new MutationObserver(() => {
            const cards = slider.querySelectorAll(".card");
            const nextButton = slider.querySelector(".slick-next");

            if (nextButton) { // Check only if the next button exists
                cards.forEach(card => {
                    const fillerCard = card.parentNode.querySelector(".fillercard");

                    if (fillerCard) {
                        console.log('FillerCard found! Hiding slick-next button.');
                        nextButton.style.display = "none";
                        observer.disconnect(); // Stop observing once handled
                    }
                });
            }
        });

        // Observe changes to the slider's DOM
        observer.observe(slider, { childList: true, subtree: true });
    });
});


document.addEventListener("DOMContentLoaded", () => {
  const counters = document.querySelectorAll(".stat-number");

  const countUp = (counter) => {
    const target = +counter.getAttribute("data-target");
    const speed = 200; // Adjust speed here
    const increment = target / speed;

    const updateCount = () => {
      const current = +counter.innerText;
      if (current < target) {
        counter.innerText = Math.ceil(current + increment);
        requestAnimationFrame(updateCount);
      } else {
        counter.innerText = target + "+"; // Add the "+" after animation completes
      }
    };

    updateCount();
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        countUp(entry.target);
        observer.unobserve(entry.target); // Stop observing once counted
      }
    });
  });

  counters.forEach((counter) => observer.observe(counter));
});
