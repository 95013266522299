import slick from 'slick-carousel';

$(document).ready(function(){


  $('.slider-track-slider').slick({
    slidesToShow: 8,      // Number of slides visible at a time
    slidesToScroll: 2,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
    responsive: [         // Responsive settings
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });


  $('.slider-track-8slider').slick({
    slidesToShow: 8,      // Number of slides visible at a time
    slidesToScroll: 1,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
    responsive: [         // Responsive settings
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });

  $('.LegalflixCategories .LegalflixContentDetailPage .speaker-cards').slick({
    slidesToShow: 8,      // Number of slides visible at a time
    slidesToScroll: 4,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 5000,  // Time between slides
    responsive: [         // Responsive settings
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });

  $('.partnerimages-slider-active').slick({
    slidesToShow: 8,      // Number of slides visible at a time
    slidesToScroll: 2,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: true,       // Infinite looping
    autoplay: true,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
    responsive: [         // Responsive settings
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });




  $('.slider-track-6slider').slick({
    slidesToShow: 6,      // Number of slides visible at a time
    slidesToScroll: 2,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
    responsive: [         // Responsive settings
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });

  $('.slider-track-2slider').slick({
    slidesToShow: 2,      // Number of slides visible at a time
    slidesToScroll: 2,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
    responsive: [         // Re
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });

  $('.slider-track-5slider').slick({
    slidesToShow: 5,      // Number of slides visible at a time
    slidesToScroll: 2,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
    responsive: [         // Responsive settings
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 9
        }
      },
      {
        breakpoint: 2700,
        settings: {
          slidesToShow: 7
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1460,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });

  $('.slider-track-4slider').slick({
    slidesToShow: 4,      // Number of slides visible at a time
    slidesToScroll: 2,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 7
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5
        }
      },     // Responsive settings
      {
        breakpoint: 1460,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });

  $('.slider-track-3slider').slick({
    slidesToShow: 3,      // Number of slides visible at a time
    slidesToScroll: 3,    // Number of slides to scroll
    arrows: true,         // Show navigation arrows
    dots: false,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3
        }
      },     // Responsive settings
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });

  $('.slider-track-addslider').slick({
    slidesToShow: 1,      // Number of slides visible at a time
    slidesToScroll: 1,    // Number of slides to scroll
    arrows: false,         // Show navigation arrows
    dots: true,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: true,       // Enable autoplay
    autoplaySpeed: 5000,  // Time between slides
    responsive: [         // Responsive settings
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,    // Number of slides to scroll
        }
      }
    ]
  });


  $('.popupslider').slick({
    slidesToShow: 1,      // Number of slides visible at a time
    slidesToScroll: 1,    // Number of slides to scroll
    arrows: false,         // Show navigation arrows
    dots: true,           // Show navigation dots
    infinite: false,       // Infinite looping
    autoplay: false,       // Enable autoplay
    autoplaySpeed: 3000,  // Time between slides
  });


});
